.seo-score {
    background-color: #4CAF50;
    color: #fff;
    font-size: 36px;
    font-weight: bold;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    width: 200px;
    text-align: center;
    margin: 50px auto;
    box-shadow: 2px 2px 3px 2px ;

}