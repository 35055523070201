.centered-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height:20vh;

  }
  
  input {
    padding: 10px;
    margin: 5px;
    width: 450px;
    border-radius: 5px;
    box-shadow: 5px 6px 5px rgba(139, 124, 124, 0.5);
    font-size: 20px;
    font-family: 'Times New Roman', Times, serif;
  }
  
  button {
    padding: 10px 20px;
    margin: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    width: 200px;
    border-radius: 5px;
    font-size: 25px;
    font-family: 'Times New Roman', Times, serif;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  